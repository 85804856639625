import axios from "axios";
import { campaignActions } from "./campaign";
import { backend_base_url } from "../../util/constants";
import { message } from "antd";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createCampaign = (campaign) => {
  return async (dispatch) => {
    await axios
      .post(`${backend_base_url}/campaign`, campaign)
      .then((resp) => {
        dispatch(campaignActions.createCampaign(resp.data.body));
        if (resp.data.body) {
          message.success(
            `Campaign ${resp.data.body.title} created successfully`
          );
        }
      })
      .catch((error) => console.log(error));
  };
};

// export const getAllCampaigns = () => {
//   return async (dispatch) => {
//     await axios
//       .get(`${backend_base_url}/campaign`)
//       .then((resp) => {
//         dispatch(campaignActions.getAllCampaigns(resp.data));
//       })
//       .catch((error) => console.log(error));
//   };
// };
export const getAllCampaigns = createAsyncThunk("getallcampaigns", async () => {
  try {
    const res = await axios.get(`${backend_base_url}/campaign`);
    return res.data;
  } catch (error) {
    console.log("error from get all campaigns", error);
  }
});

export const deleteCampaign = (id) => {
  return async (dispatch) => {
    await axios
      .delete(`${backend_base_url}/campaign?id=${id}`)
      .then((resp) => {
        dispatch(campaignActions.deleteCampaign(resp.data));
        if (resp.data) {
          message.success(`Campaign ${resp.data.title} deleted successfully`);
        }
      })
      .catch((error) => console.log(error));
  };
};
