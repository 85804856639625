import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  Input,
  message,
  DatePicker,
  Select,
  Checkbox,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import pdfImg from "../../../assests/img/pdf-img.webp";
import PreviewUploadedImage from "./PreviewUploadedImage";
import FloatLabel from "../../../util/FloatLabel";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getAllGroups } from "../../../redux/group/group-actions";
import {
  getUsersByGroupId,
  getAllCities,
  getUsersByCity,
  getAllUsers,
} from "../../../redux/user/user-actions";
import { createCampaign } from "../../../redux/campaign/campaign-actions";

const { Option } = Select;
dayjs.extend(customParseFormat);

const { TextArea } = Input;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function CreateCampaign(props) {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputFile, setInputFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [campaignMessage, setCampaignMessage] = useState("");
  const [scheduledDate, setScheduledDate] = useState("");
  const [title, setTitle] = useState("");
  const [userList, setUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [checked, setChecked] = useState(false);
  const groups = useSelector((state) => state.group.groups);
  const users = useSelector((state) => state.user.users);
  const cities = useSelector((state) => state.user.cities);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    dispatch(getAllGroups());
    dispatch(getAllCities());
    dispatch(getAllUsers());
  };

  const onCheckChange = (e) => {
    if (e.target.checked) {
      setUserList(users);
      setChecked(e.target.checked);
    } else {
      setUserList([]);
      setChecked(e.target.checked);
    }
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today and today

    return current && current < dayjs().startOf("day");
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isvideo = file.type === "video/mp4";
    const ispdf = file.type === "application/pdf";
    const isLt5M = file.size / 1024 / 1024 < 5;
    const isLt16M = file.size / 1024 / 1024 < 16;
    const isLt10 = file.size / 1024 / 1024 < 10;

    if (isJpgOrPng) {
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
        return false;
      }
    } else if (isvideo) {
      if (!isLt16M) {
        message.error("Video must smaller than 16MB!");
        return false;
      }
    } else if (ispdf) {
      if (!isLt10) {
        message.error("PDF must smaller than 10MB!");
        return false;
      }
    } else {
      message.error("You can only upload JPG/PNG/MP4 file!");
      return false;
    }
    return true;
  };

  const handleFileChange = async (file) => {
    let resp = await beforeUpload(file.target.files[0]);
    if (resp) {
      setFileName(file.target.files[0].name);
      file.target.files[0].preview = await getBase64(file.target.files[0]);
      let newFileObj = [];
      newFileObj.push(file.target.files[0]);
      setFileList(newFileObj);
      setFile(URL.createObjectURL(file.target.files[0]));
      setFileType(file.target.files[0].type);
    }
  };

  const onChange = (event) => {
    setCampaignMessage(event.target.value);
  };

  const onSubmit = async () => {
    if (fileList.length !== 0) {
      let finalUrl = "";
      let url = fileList[0].preview.split("base64,");
      finalUrl = finalUrl.concat(url[1]);
      let campaignObj = {
        media: true,
        document_type: fileType,
        mediaBase64: finalUrl,
        document_name: fileName,
        title,
        campaignMessage,
        scheduledDate,
        members: userList,
      };
      dispatch(createCampaign(campaignObj));
      handleModalCancel();
    } else {
      let campaignObj = {
        title,
        campaignMessage,
        scheduledDate,
        members: userList,
      };
      dispatch(createCampaign(campaignObj));
      handleModalCancel();
    }
  };

  const removeUploadedFile = () => {
    setFileList([]);
    setFile(null);
    setFileType("");
    setFileName("");
    setTitle("");
    setUserList([]);
    setGroupList([]);
    setCityList([]);
    setCampaignMessage("");
    setScheduledDate("");
    setChecked(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleModalCancel = () => {
    setOpen(false);
    removeUploadedFile();
  };

  const onDateChange = (value, dateString) => {
    let utcDate = new Date(dateString).toUTCString();
    let convertedUtcDate = JSON.stringify(new Date(utcDate)).replaceAll(
      '"',
      ""
    );
    setScheduledDate(convertedUtcDate);
  };

  const onUsersChange = (value) => {
    let data = value.map((e) => {
      return JSON.parse(e);
    });
    setUserList(data);
  };

  const onGroupChange = (value) => {
    setGroupList(value);
    dispatch(getUsersByGroupId(value));
  };
  const onCityChange = (value) => {
    setCityList(value);
    dispatch(getUsersByCity(value));
  };
  return (
    <>
      <button onClick={showModal} class="float-end create-btn" role="button">
        <span class="text">
          Create
          <svg
            width={15}
            height={15}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 mb-1 ms-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
            />
          </svg>
        </span>
      </button>
      <Modal
        open={open}
        onCancel={handleModalCancel}
        title=""
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="create-campaign"
          className="create-campaign"
          onFinish={onSubmit}
          initialValues={{ remember: true }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              margin: "0 0 10px 0",
              justifyContent: "center",
            }}
          >
            <h6 style={{ textAlign: "center" }}>Create Campaign</h6>
          </div>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name="upload" valuePropName="fileList">
                {file ? (
                  <Row gutter={[8, 8]}>
                    {(fileType == "image/png" || fileType == "image/jpeg") && (
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div style={{ textAlign: "center" }}>
                          <img width={125} height={125} src={file} />
                        </div>
                      </Col>
                    )}
                    {fileType == "video/mp4" && (
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div style={{ textAlign: "center" }}>
                          <video width="125" height="125" controls>
                            <source src={file} type="video/mp4" />
                          </video>
                        </div>
                      </Col>
                    )}
                    {fileType == "application/pdf" && (
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div style={{ textAlign: "center" }}>
                          <img width={125} height={125} src={pdfImg} />
                        </div>
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <div style={{ margin: "0 10px 0 auto" }}>
                          {fileType !== "video/mp4" && (
                            <PreviewUploadedImage
                              file={file}
                              fileType={fileType}
                            />
                          )}
                        </div>
                        <div style={{ margin: "0 auto 0 0" }}>
                          <button
                            onClick={removeUploadedFile}
                            style={{ width: "50px" }}
                            type="button"
                            class="btn btn-outline-danger btn-sm"
                          >
                            <DeleteOutlined />
                          </button>
                        </div>
                      </Row>
                    </Col>
                    <div style={{ margin: "0 auto" }}>
                      <label>
                        <h6>{fileName}</h6>
                      </label>
                    </div>
                  </Row>
                ) : (
                  <div className="text-center">
                    <label
                      for="inputFile"
                      style={{
                        fontSize: "25px",
                        textAlign: "-webkit-center",
                      }}
                    >
                      <div class="drag-area ">
                        <h6>Upload</h6> <PlusOutlined />
                        <input
                          class="form-control"
                          type="file"
                          id="inputFile"
                          name="inputFile"
                          value={inputFile}
                          onChange={handleFileChange}
                          hidden
                          accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4"
                        />
                      </div>
                    </label>
                  </div>
                )}
              </Form.Item>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Campaign Title" name="title" value={title}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please type Title!",
                    },
                  ]}
                >
                  <Input
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel
                label="Select Date"
                name="scheduledDate"
                value={scheduledDate}
              >
                <Form.Item
                  name="scheduledDate"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Date!",
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    disabledDate={disabledDate}
                    placeholder={false}
                    showTime
                    onChange={onDateChange}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FloatLabel label="Select City" name="cityList" value={cityList}>
                <Form.Item name="cityList">
                  <Select
                    allowClear
                    maxTagCount="responsive"
                    maxTagPlaceholder={(omittedValues) =>
                      `+ ${omittedValues.length} City...`
                    }
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    onChange={onCityChange}
                    value={cityList}
                    disabled={groupList.length > 0 || checked}
                  >
                    {cities?.map((data, i) => (
                      <Option key={i} value={data}>
                        {data}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FloatLabel
                label="Select Group"
                name="groupList"
                value={groupList}
              >
                <Form.Item name="groupList">
                  <Select
                    allowClear
                    maxTagCount="responsive"
                    maxTagPlaceholder={(omittedValues) =>
                      `+ ${omittedValues.length} Group...`
                    }
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    onChange={onGroupChange}
                    value={groupList}
                    disabled={cityList.length > 0 || checked}
                  >
                    {groups?.map((data, i) => (
                      <Option key={i} value={data._id}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={22} sm={22} md={23} lg={23} xl={23}>
              <FloatLabel label="Select Users" name="userList" value={userList}>
                <Form.Item name="userList">
                  <Select
                    allowClear
                    maxTagCount="responsive"
                    maxTagPlaceholder={(omittedValues) =>
                      `+ ${omittedValues.length} Users...`
                    }
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    value={userList}
                    disabled={checked}
                    onChange={onUsersChange}
                  >
                    {users?.map((data, i) => (
                      <Option key={i} value={JSON.stringify(data)}>
                        {data.name} ({data.mobileNumber})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={2} sm={2} md={1} lg={1} xl={1}>
              <Tooltip
                title={!checked ? "Select all Users" : "Select users manually"}
              >
                <p
                  style={{
                    margin: "6px 0 0 0",
                  }}
                >
                  <Checkbox checked={checked} onChange={onCheckChange} />
                </p>
              </Tooltip>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FloatLabel
                label="Type about Campaign..."
                name="campaignMessage"
                value={campaignMessage}
              >
                <Form.Item
                  name="campaignMessage"
                  rules={[
                    {
                      required: true,
                      message: "Please type description!",
                    },
                  ]}
                >
                  <TextArea
                    name="campaignMessage"
                    value={campaignMessage}
                    onChange={onChange}
                    rows={6}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>
          <Form.Item style={{ float: "right" }}>
            <Button
              className="close-modal"
              style={{ margin: "0 10px 0 0" }}
              onClick={handleModalCancel}
            >
              Close
            </Button>

            <Button type="primary" htmlType="submit" className="ok-modal">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default CreateCampaign;
