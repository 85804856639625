import React from "react";
import clock from "../../../assests/img/clock-time.gif";
import group from "../../../assests/img/group.gif";
import campaign from "../../../assests/img/campaign.gif";
import completed from "../../../assests/img/completed.gif";
import { Row, Col } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCardData } from "../../../redux/dashboard/dashboard-actions";

function CountCards(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.dashboard.cardsData);

  useEffect(() => {
    dispatch(getCardData());
  }, []);
  return (
    <Row className="statistics-card">
      <Col span={24}>
        <div class="row ">
          <div class="col-12 col-sm-6 col-md-3">
            <div class=" my-card has-background-gradient-teal">
              <div class="my-auto me-auto ms-4">
                <span class=" my-icon py-3 px-4">
                  <img
                    style={{ margin: "-10px 0 0 0" }}
                    width={65}
                    height={65}
                    src={campaign}
                  />
                </span>
              </div>
              <div class="my-auto px-4">
                <p class="mb-1">Campaigns</p>
                <h2>{data.campaigns}</h2>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <div class=" my-card has-background-gradient-blue">
              <div class="my-auto me-auto ms-4">
                <span class="fa fa-users my-icon py-3 px-4">
                  <img
                    style={{ margin: "-10px 0 0 0" }}
                    width={65}
                    height={65}
                    src={group}
                  />
                </span>
              </div>
              <div class="my-auto px-4">
                <p class="mb-1">Groups</p>
                <h2>{data.groups}</h2>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <div class=" my-card has-background-gradient-green">
              <div class="my-auto me-auto ms-4">
                <span class="fa fa-users my-icon py-3 px-4">
                  <img
                    style={{ margin: "-10px 0 0 0" }}
                    width={65}
                    height={65}
                    src={completed}
                  />
                </span>
              </div>
              <div class="my-auto px-4">
                <p class="mb-1">Completed</p>
                <h2>{data.completed_campaigns}</h2>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-3">
            <div class=" my-card has-background-gradient-orange">
              <div class="my-auto me-auto ms-4">
                <span class="fa fa-users my-icon py-3 px-4">
                  <img
                    style={{ margin: "-10px 0 0 0" }}
                    width={65}
                    height={65}
                    src={clock}
                  />
                </span>
              </div>
              <div class="my-auto px-4">
                <p class="mb-1">Scheduled</p>
                <h2>{data.scheduled_campaigns}</h2>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default CountCards;
