import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
  cardsData: [],
  chartData: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    getCardData(state, action) {
      state.cardsData = action.payload;
    },
    getChartData(state, action) {
      state.chartData = action.payload;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
