import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  Input,
  Select,
  message,
  Dropdown,
  Typography,
  Upload,
} from "antd";
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import FloatLabel from "../../../util/FloatLabel";
import { createBulkUsers, createUser } from "../../../redux/user/user-actions";
import { Country, City, State } from "country-state-city";
import {
  DownloadOutlined,
  InboxOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

const { Option } = Select;

function CreateUser(props) {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [fileList, setFileList] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [cities, setCities] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [city, setCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [country, setCountry] = useState({ code: "", name: "" });
  const [states, setStates] = useState([]);
  const [state, setState] = useState({ code: "", name: "" });
  const { Dragger } = Upload;

  const callBeforeUpload = (file) => {
    console.log("file inside call before upload", file);
  };
  const readFileContent = async (fileObj) => {
    console.log("inside read file content", fileObj);
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;

      console.log(fileContent);

      const usersObj = createUsersObj(fileContent);

      setNewUsers(usersObj);
    };

    reader.readAsText(fileObj);
  };
  const createUsersObj = (usersString) => {
    let lines = usersString.split("\n");
    console.log("string inside create obj", lines);
    let headers = lines[0].split("\r");

    headers = headers[0].split(",");
    console.log("headers inside create obj", headers);
    lines.shift();
    console.log("lines inside create obj after removing headers ", lines);
    let final_lines = [];
    lines.map((user) => {
      if (user) {
        user = user.split("\r");
        console.log("user inside map", user);
        user = user[0].split(",");
        let obj = {};
        obj["name"] = user[0].trimEnd();
        obj["email"] = user[1].trimEnd();
        obj["mobileNumber"] = user[2].trimEnd();
        obj["city"] = user[3].trimEnd();
        obj["state"] = user[4].trimEnd();
        obj["country"] = user[5].trimEnd();
        final_lines.push(obj);
      }
    });
    console.log("final liness ", final_lines);
    return final_lines;
  };
  const propsForFileUpload = {
    name: "file",
    multiple: false,
    accept: "text/csv",
    maxCount: 1,

    fileList: uploadModalOpen ? fileList : [],
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      callBeforeUpload(file);

      return false; // Prevent automatic file upload
    },
    onChange(info) {
      const { status } = info.file;
      console.log("status inside on change", status);
      console.log("info inside on change", info.fileList[0]);
      const fileObj = info.fileList[0];
      if (status !== "uploading" && status !== "removed") {
        console.log("origin file obj", fileObj.originFileObj);
        readFileContent(fileObj.originFileObj);
      }
      setFileList(info.fileList);
    },

    onDrop(e) {
      console.log("#Dropped files", e.dataTransfer.files);
    },
  };

  const downloadTemplate = () => {
    setDownloadLoading(true);
    const csvData = "name,email,mobileNumber,city,state,country";
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "template.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setDownloadLoading(false);
  };
  const handleChange = (e, value) => {
    setCountry({ code: value.value, name: value.key });
  };

  const handleStateChange = (e, value) => {
    setState({ code: value.value, name: value.key });
  };

  const handleCityChange = (e, value) => {
    setCity(value.value);
  };

  const showUploadModal = () => {
    setUploadModalOpen(true);
  };
  const handleUploadModalCancel = () => {
    setFileList([]);
    setUploadModalOpen(false);
  };
  const onSubmit = async () => {
    let userObj = {
      name,
      mobileNumber,
      email,
      city,
      state: state.name,
      country: country.name,
    };
    dispatch(createUser(userObj));
    handleModalCancel(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleModalCancel = () => {
    setOpen(false);
    setEmail("");
    setMobileNumber("");
    setName("");
    setCity("");
    setState("");
    setCountry("");
    setCities([]);
    setStates([]);
    setCountries([]);
  };

  const handleFileUpload = async () => {
    setUploadLoading(true);
    console.log("new users inside handle file upload", newUsers);
    dispatch(createBulkUsers(newUsers))
      .then((res) => {
        console.log("response from dispatch create bulk users", res);
        if (res.payload) {
          message.success("users added ");
          setUploadLoading(false);
          setUploadModalOpen(false);
        }
      })
      .catch((err) => {
        console.log("error from dispatch bulk users", err);
      });
  };

  return (
    <>
      {/* <button
        onClick={showModal}
        type="button"
        class="float-end create-btn"
        role="button"
      >
        <span class="text">
          Create User
          <svg
            width={15}
            height={15}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 mb-1 ms-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
            />
          </svg>
        </span>
      </button> */}
      <div className="d-flex justify-content-end mx-0">
        <button
          onClick={showModal}
          type="button"
          class="float-end create-btn mx-3"
          role="button"
        >
          <span class="text">
            Create User
            <svg
              width={15}
              height={15}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mb-1 ms-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
              />
            </svg>
          </span>
        </button>

        <button
          onClick={showUploadModal}
          type="button"
          class="float-end create-btn"
          role="button"
        >
          <span class="text">
            Create Bulk Users
            <svg
              width={15}
              height={15}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 mb-1 ms-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                // d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                // d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5z"
                d="M12 2L2 12h4v8h8v-8h4L12 2z"
              />
            </svg>
          </span>
        </button>
      </div>

      <Modal
        open={open}
        onCancel={handleModalCancel}
        title=""
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="create-user"
          className="create-user"
          onFinish={onSubmit}
          initialValues={{ remember: true }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              margin: "0 0 10px 0",
              justifyContent: "center",
            }}
          >
            <h6 style={{ textAlign: "center" }}>Create User</h6>
          </div>
          <Row gutter={[8, 0]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Name" name="name" value={name}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Name!",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Email" name="email" value={email}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please enter E-mail!",
                    },
                  ]}
                >
                  <Input
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel
                label="Mobile No."
                name="mobileNumber"
                value={mobileNumber}
              >
                <Form.Item
                  name="mobileNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile number!",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        var hasAlphabets = /^[0-9]+$/;
                        if (
                          parseInt(getFieldValue("mobileNumber")) &&
                          hasAlphabets.test(getFieldValue("mobileNumber"))
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Should contain numbers!");
                      },
                    }),
                  ]}
                >
                  <Input
                    type="string"
                    name="mobileNumber"
                    id="mobileNumber"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel
                label="Select Country"
                name="countries"
                value={countries}
              >
                <Form.Item
                  name="countries"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Country",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    placeholder="Select Country"
                    onChange={handleChange}
                    onClick={() => setCountries(Country.getAllCountries())}
                    onFocus={() => setCountries(Country.getAllCountries())}
                    value={countries}
                  >
                    {countries?.map((data) => (
                      <Option key={data.name} value={data.isoCode}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Select State" name="states" value={states}>
                <Form.Item
                  name="states"
                  rules={[
                    {
                      required: true,
                      message: "Please Select State",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    placeholder="Select State"
                    onChange={handleStateChange}
                    onClick={() =>
                      setStates(State.getStatesOfCountry(country.code))
                    }
                    onFocus={() =>
                      setStates(State.getStatesOfCountry(country.code))
                    }
                    value={states}
                  >
                    {states?.map((data) => (
                      <Option key={data.name} value={data.isoCode}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Select City" name="cities" value={cities}>
                <Form.Item
                  name="cities"
                  rules={[
                    {
                      required: true,
                      message: "Please Select City",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    placeholder="Select City"
                    onChange={handleCityChange}
                    onClick={() =>
                      setCities(City.getCitiesOfState(country.code, state.code))
                    }
                    onFocus={() =>
                      setCities(City.getCitiesOfState(country.code, state.code))
                    }
                    value={cities}
                  >
                    {cities.map((data, i) => (
                      <Option key={i} value={data.name}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>
          <Form.Item style={{ float: "right" }}>
            <Button
              className="close-modal"
              style={{ margin: "0 10px 0 0" }}
              onClick={handleModalCancel}
            >
              Close
            </Button>

            <Button type="primary" htmlType="submit" className="ok-modal">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={uploadModalOpen}
        onCancel={handleUploadModalCancel}
        title=""
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="create-users"
          className="create-users"
          // onFinish={onSubmit}
          initialValues={{ remember: true }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              margin: "0 0 10px 0",
              justifyContent: "center",
            }}
          >
            <h6 style={{ textAlign: "center" }}>Create Multiple Users</h6>
          </div>
          <Form.Item>
            <Typography style={{ fontWeight: "470", fontSize: "16px" }}>
              *Download file template to fill data before uploading
            </Typography>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              size="medium"
              loading={downloadLoading}
              onClick={downloadTemplate}
              style={{ marginTop: "5px" }}
            >
              Download
            </Button>
          </Form.Item>
          <Form.Item name="upload">
            <Dragger {...propsForFileUpload}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag .csv file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Add bulk data of new users using .csv file
              </p>
            </Dragger>
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              className="close-modal"
              style={{ margin: "0 10px 0 0" }}
              onClick={handleUploadModalCancel}
            >
              Close
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              className="ok-modal"
              loading={uploadLoading}
              onClick={handleFileUpload}
            >
              Upload
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default CreateUser;
