import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "../../BreadCrumbs";
import CreateUser from "./CreateUser";
import ListUser from "./ListUser";

export default function User(props) {
  return (
    <>
      <div className="container">
        <Row className="title-row">
          <Col xs={12} sm={12} md={20} lg={20} xl={20}>
            <h2 className="page-title">User Management</h2>
            <Breadcrumb location={props.location} />
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <CreateUser />
          </Col>
        </Row>
        <Row className="statistics-card">
          <Col span={24}>
            <ListUser />
          </Col>
        </Row>
      </div>
    </>
  );
}
