import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "../../BreadCrumbs";
import CreateGroup from "./CreateGroup";
import ListGroup from "./ListGroup";

export default function Group(props) {
  return (
    <>
      <div className="container">
        <Row className="title-row">
          <Col xs={12} sm={12} md={20} lg={20} xl={20}>
            <h2 className="page-title">Group Management</h2>
            <Breadcrumb location={props.location} />
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <CreateGroup />
          </Col>
        </Row>
        <Row className="statistics-card">
          <Col span={24}>
            <ListGroup />
          </Col>
        </Row>
      </div>
    </>
  );
}
