import React from "react";
import { Breadcrumb } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { HomeFilled } from "@ant-design/icons";

const BreadCrumbs = (props) => {
  let location = props.location;

  const navigate = useNavigate();
  const loc = location;
  const pathname = loc.pathname;

  let pathnames = pathname.split("/").filter((item) => item);

  const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

  return (
    <div>
      <Breadcrumb>
        <Link to="/">
          <svg
            width={18}
            height={18}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 mt-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        </Link>
        &nbsp; / &nbsp;
        {pathnames.map((name, index) => {
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <>
              <Breadcrumb.Item>{capatilize(name)}</Breadcrumb.Item>
            </>
          ) : (
            <>
              <Breadcrumb.Item>
                <label
                  style={{
                    color: "#857fe8",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  type="link"
                  onClick={() => {
                    let current_index = pathnames.length - 1;
                    let desired_index = pathnames.indexOf(name.toLowerCase());
                    let navigate_index = current_index - desired_index;
                    navigate(-navigate_index);
                  }}
                >
                  {capatilize(name)}
                </label>
              </Breadcrumb.Item>
            </>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumbs;
