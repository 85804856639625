import axios from "axios";
import { dashboardActions } from "./dashboard";
import { backend_base_url } from "../../util/constants";

export const getCardData = () => {
  return async (dispatch) => {
    await axios
      .get(`${backend_base_url}/dashboard`)
      .then((resp) => {
        dispatch(dashboardActions.getCardData(resp.data.body));
      })
      .catch((error) => console.log(error));
  };
};
export const getChartData = () => {
  return async (dispatch) => {
    await axios
      .get(`${backend_base_url}/dashboardchart`)
      .then((resp) => {
        dispatch(dashboardActions.getChartData(resp.data.body));
      })
      .catch((error) => console.log(error));
  };
};
