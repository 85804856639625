import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Spin,
  Table,
  Tag,
  Row,
  Col,
  Tooltip,
  Popconfirm,
  Button,
  Space,
  Input,
  Empty,
} from "antd";
import {
  DeleteOutlined,
  DownCircleTwoTone,
  EditOutlined,
  QuestionCircleOutlined,
  RightCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import FloatLabel from "../../../util/FloatLabel";
import { getAllUsers, deleteUser } from "../../../redux/user/user-actions";
import UpdateUser from "./UpdateUser";

function ListUser(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const searchInput = useRef(null);
  const users = useSelector((state) => state.user.users);

  const dispatch = useDispatch();

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    setLoading(true);
    dispatch(getAllUsers())
      .then((res) => {
        console.log("response from get all users", res);

        setLoading(false);
      })
      .catch((err) => {
        console.log("error from get all users", err);
      });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <FloatLabel
          label={`Search ${dataIndex}`}
          // name="selectedKeys[0]"
          value={selectedKeys[0]}
        >
          <Input
            ref={searchInput}
            // placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        </FloatLabel>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onDelete = (id) => {
    dispatch(deleteUser(id));
  };

  const userCol = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      align: "left",
      ...getColumnSearchProps("mobileNumber"),
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      ...getColumnSearchProps("email"),
    },
    {
      title: "City",
      dataIndex: "city",
      align: "left",
      ...getColumnSearchProps("city"),
    },
    {
      title: "State",
      dataIndex: "state",
      align: "left",
      ...getColumnSearchProps("state"),
    },
    {
      title: "Country",
      dataIndex: "country",
      align: "left",
      ...getColumnSearchProps("country"),
    },
    {
      title: "Actions",
      key: "_id",
      align: "center",
      width: 90,
      render: (record) => {
        return (
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <UpdateUser user={record} />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Tooltip title="Delete User">
                <Popconfirm
                  placement="left"
                  title="Are you sure, you want to delete this User?"
                  onConfirm={() => onDelete(record._id)}
                  // onCancel={() => this.cancelCallOnDelete()}
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "#d91d0f",
                      }}
                    />
                  }
                >
                  <DeleteOutlined
                    twoToneColor="#000000"
                    style={{
                      fontSize: "15px",
                    }}
                    size="small"
                    shape="circle"
                  />
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <div className="card">
      <div className="card-body">
        <Table
          columns={userCol}
          dataSource={users}
          loading={loading}
          rowKey="_id"
          align="left"
          // scroll={{
          //   y: 400,
          // }}
        />
      </div>
    </div>
  );
}

export default ListUser;
