import { createSlice } from "@reduxjs/toolkit";
import { build } from "pdfjs-dist";
import { createGroup, getAllGroups } from "./group-actions";

const initialGroupState = {
  groups: [],
};

const groupSlice = createSlice({
  name: "group",
  extraReducers: (builder) => {
    builder.addCase(createGroup.fulfilled, (state, action) => {
      state.groups = [...state.groups, action.payload];
    });
    builder.addCase(createGroup.pending, (state, action) => {
      state.groups = [...state.groups];
    });
    builder.addCase(createGroup.rejected, (state, action) => {
      state.groups = [...state.groups];
    });
    builder.addCase(getAllGroups.fulfilled, (state, action) => {
      state.groups = action.payload;
    });
    builder.addCase(getAllGroups.pending, (state, action) => {
      state.groups = [];
    });
    builder.addCase(getAllGroups.rejected, (state, action) => {
      state.groups = [];
    });
  },
  initialState: initialGroupState,
  reducers: {
    // getAllGroups(state, action) {
    //   state.groups = action.payload;
    // },
    // createGroup(state, action) {
    //   state.groups = [...state.groups, action.payload];
    // },
    deleteGroup(state, action) {
      let index = state.groups.findIndex(
        ({ _id }) => _id === action.payload._id
      );
      state.groups.splice(index, 1);
    },
    deleteUserFromGroup(state, action) {
      let index = state.groups.members.findIndex(
        ({ _id }) => _id === action.payload.members[0]._id
      );
      state.groups.members.splice(index, 1);
    },
    updateGroup(state, action) {
      const index = state.groups.findIndex(
        ({ _id }) => _id === action.payload._id
      );
      if (index !== -1) {
        state.groups[index] = action.payload;
      }
    },
  },
});

export const groupActions = groupSlice.actions;

export default groupSlice.reducer;
