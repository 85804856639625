import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { Tag, Table, Button, Row, Col, Input, Space, Empty, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ViewCampaignDetails from "../campaign/ViewCampaignDetails";
import { getAllCampaigns } from "../../../redux/campaign/campaign-actions";
import FloatLabel from "../../../util/FloatLabel";

function UpcomingCampaigns(props) {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const campaigns = useSelector((state) => state.campaign.campaigns);
  const dispatch = useDispatch();

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    dispatch(getAllCampaigns());
  };
  useEffect(() => {
    if (campaigns.length > 0) {
      let temp = campaigns.filter((item) => item.status === "Scheduled");
      setData(temp);
    }
  }, [campaigns]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <FloatLabel
          label={`Search ${dataIndex}`}
          // name="selectedKeys[0]"
          value={selectedKeys[0]}
        >
          <Input
            ref={searchInput}
            // placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        </FloatLabel>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const campaignCol = [
    {
      title: "Title",
      dataIndex: "title",
      align: "left",
      ...getColumnSearchProps("title"),
    },

    {
      title: "Scheduled At",
      dataIndex: "scheduledDate",
      align: "left",
      render: (record) => {
        return dayjs(record).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "Members",
      key: "operation",
      align: "center",
      render: (record) => {
        return (
          <>
            {record.members.length > 0 ? (
              <Tag color="geekblue">{record.members.length}</Tag>
            ) : (
              <Tag color="volcano">{record.members.length}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "_id",
      align: "center",
      render: (record) => {
        return (
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <ViewCampaignDetails campaign={record} />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Col xs={24} sm={24} md={12} lg={16} xl={16}>
      <div className="card">
        <div className="card-body">
          <h6 className=" text-secondary ms-3">Upcoming Campaings</h6>
          <Table
            columns={campaignCol}
            dataSource={data}
            rowKey="_id"
            align="left"
            scroll={{
              y: 300,
            }}
          />
        </div>
      </div>
    </Col>
  );
}

export default UpcomingCampaigns;
