import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "../../BreadCrumbs";
import CreateCampaign from "./CreateCampaign";
import ListCampaign from "./ListCampaign";

export default function Campaign(props) {
  return (
    <>
      <div className="container">
        <Row className="title-row">
          <Col xs={12} sm={12} md={20} lg={20} xl={20}>
            <h2 className="page-title">Campaign Management</h2>
            <Breadcrumb location={props.location} />
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <CreateCampaign />
          </Col>
        </Row>
        <Row className="statistics-card">
          <Col span={24}>
            <ListCampaign />
          </Col>
        </Row>
      </div>
    </>
  );
}
