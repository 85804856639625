import { EyeOutlined } from "@ant-design/icons";
import { Modal, Tooltip, Card } from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";
import noImg from "../../../assests/img/no_img.png";
const { Meta } = Card;

function ViewCampaignDetails(props) {
  const [open, setOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);

  const showModal = () => {
    setOpen(true);
  };

  const handleModalCancel = () => {
    setOpen(false);
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    console.log(byteArrays);

    return new File(byteArrays, "pot", { type: contentType });
  }

  useEffect(() => {
    if (props.campaign.mediaBase64) {
      var str = props.campaign.mediaBase64;
      var enc = window.atob(str);

      var image = new File([enc], props.campaign.document_name, {
        type: props.campaign.document_type,
      });
      var file = b64toBlob(str, props.campaign.document_type);
      console.log("11", file);
      var fileb = new File([], "ranom", {
        type: props.campaign.document_type,
      });
      console.log(file.size);
      console.log(fileb.size);
      var imgURL = URL.createObjectURL(file);
      setImgUrl(imgURL);
      console.log("18", imgURL);
      var res = "Encoded String: " + image;
    }
  }, []);
  return (
    <>
      <Tooltip title="Campaign Details">
        <EyeOutlined
          twoToneColor="#000000"
          style={{ fontSize: "15px", cursor: "pointer" }}
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        open={open}
        onCancel={handleModalCancel}
        title=""
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <span className="view-details">
          <Card
            cover={
              <img
                alt={props.campaign.mediaBase64 ? "Image" : "No Media Uploaded"}
                src={props.campaign.mediaBase64 ? imgUrl : noImg}
              />
            }
          >
            <Meta
              title={
                props.campaign.title +
                "\n(" +
                dayjs(props.campaign.scheduledDate).format(
                  "YYYY-MM-DD HH:mm:ss"
                ) +
                ")"
              }
              description={props.campaign.campaignMessage}
            />
          </Card>
        </span>
      </Modal>
    </>
  );
}

export default ViewCampaignDetails;
