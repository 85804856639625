import React, { useEffect } from "react";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { Provider } from "react-redux";
import store from "./redux/index";
import { BrowserRouter } from "react-router-dom";
import NavRoutes from "./NavRoutes";
import { brand } from "./util/constants";
import "bootstrap/dist/css/bootstrap.min.css";

function App({ instance }) {
  useEffect(() => {
    document.title = brand.NAME;
  }, []);
  return (
    <MsalProvider instance={instance}>
      <Provider store={store}>
        <BrowserRouter>
          <NavRoutes />
        </BrowserRouter>
      </Provider>
    </MsalProvider>
  );
}

export default App;
