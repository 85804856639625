import React from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { brand, routes } from "../../util/constants";
import logo from "../../assests/img/advertisement.png";

export default function SiderMenu(props) {
  return (
    <div
      className={props.collapsed ? "sider-menu" : "sider-menu menu-position"}
    >
      <div className="side-header">
        <div className="brand-name">
          <div className="brand-logo">
            <img className="logo" src={logo} alt="Logo" />
            <span
              className="app-name mt-1"
              style={{ fontSize: "15px" }}
              mode="full"
            >
              {brand.NAME}
            </span>
          </div>
        </div>
      </div>

      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
      >
        <Menu.Item
          key={routes.ADMIN_DASHBOARD}
          icon={
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          }
        >
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.ADMIN_DASHBOARD}
          >
            Dashboard
          </NavLink>
        </Menu.Item>
        <p class="menu-subhead" mode="full">
          OPERATIONS
        </p>
        <Menu.Item
          key={routes.CAMPAIGN}
          icon={
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
              />
            </svg>
          }
        >
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.CAMPAIGN}
          >
            Campaign Management
          </NavLink>
        </Menu.Item>

        <Menu.Item
          key={routes.GROUP}
          icon={
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
              />
            </svg>
          }
        >
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.GROUP}
          >
            Group Management
          </NavLink>
        </Menu.Item>

        <Menu.Item
          key={routes.USER}
          icon={
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
          }
        >
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.USER}
          >
            User Management
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
}
