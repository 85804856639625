export const routes = {
  HOME: "/",
  ADMIN_DASHBOARD: "/dashboard",
  CAMPAIGN: "/campaigns",
  USER: "/users",
  GROUP: "/groups",
};
export const brand = {
  NAME: "Campaign Management",
};

export const backend_base_url =
  "https://js2x8ojx2i.execute-api.us-east-1.amazonaws.com/dev";
