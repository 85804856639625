import axios from "axios";
import group, { groupActions } from "./group";
import { backend_base_url } from "../../util/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

// export const getAllGroups = () => {
//   return async (dispatch) => {
//     await axios
//       .get(`${backend_base_url}/group`)
//       .then((resp) => {
//         dispatch(groupActions.getAllGroups(resp.data));
//       })
//       .catch((error) => console.log(error));
//   };
// };

// export const createGroup = (group) => {
//   return async (dispatch) => {
//     await axios
//       .post(`${backend_base_url}/group`, group)
//       .then((resp) => {
//         dispatch(groupActions.createGroup(resp.data.body));
//         if (resp.data.body) {
//           message.success(`Group ${resp.data.body.name} created successfully`);
//         }
//       })
//       .catch((error) => console.log(error));
//   };
// };

export const createGroup = createAsyncThunk("createGroup", async (group) => {
  try {
    const res = await axios.post(`${backend_base_url}/group`, group);
    return res.data.body;
  } catch (error) {
    console.log("error from create group api", error);
  }
});

export const getAllGroups = createAsyncThunk("getallgroups", async (group) => {
  try {
    const res = await axios.get(`${backend_base_url}/group`);
    return res.data;
  } catch (error) {
    console.log("error from get all groups", error);
  }
});
export const deleteGroup = (id) => {
  return async (dispatch) => {
    await axios
      .delete(`${backend_base_url}/group?id=${id}`)
      .then((resp) => {
        dispatch(groupActions.deleteGroup(resp.data));
        if (resp.data) {
          message.success(`Group ${resp.data.name} deleted successfully`);
        }
      })
      .catch((error) => console.log(error));
  };
};

export const deleteUserFromGroup = (group) => {
  return async (dispatch) => {
    await axios
      .delete(
        `${backend_base_url}/group/user?groupId=${group.groupId}&userId=${group.userId}`
      )
      .then((resp) => {
        dispatch(groupActions.deleteUserFromGroup(resp.data));
      })
      .catch((error) => console.log(error));
  };
};

export const updateGroup = (group, id) => {
  return async (dispatch) => {
    await axios
      .patch(`${backend_base_url}/group?id=${id}`, group)
      .then((resp) => {
        dispatch(groupActions.updateGroup(resp.data));
        if (resp.data) {
          message.success(`Group updated successfully`);
        }
      })
      .catch((error) => console.log(error));
  };
};
