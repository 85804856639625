import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCampaign,
  getAllCampaigns,
} from "../../../redux/campaign/campaign-actions";
import {
  Spin,
  Table,
  Tag,
  Row,
  Col,
  Tooltip,
  Popconfirm,
  Button,
  Space,
  Input,
  Empty,
} from "antd";
import {
  DeleteOutlined,
  DownCircleTwoTone,
  QuestionCircleOutlined,
  RightCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import FloatLabel from "../../../util/FloatLabel";
import ViewCampaignDetails from "./ViewCampaignDetails";
import dayjs from "dayjs";
import UpdateCampaign from "./UpdateCampaign";

function ListCampaign(props) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const searchInput = useRef(null);
  const campaigns = useSelector((state) => state.campaign.campaigns);

  const dispatch = useDispatch();

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = async () => {
    // setLoading(true);
    dispatch(getAllCampaigns())
      .then((res) => {
        console.log("response from get all campaigns", res);
        // if (res.payload) setLoading(false);
      })
      .catch((err) => {
        console.log("error from get all campaigns", err);
      });
  };

  const onDeleteGroup = async (id) => {
    dispatch(deleteCampaign(id));
  };

  const expandedRowRender = (e, index) => {
    const userCol = [
      {
        title: "Name",
        dataIndex: "name",
        align: "left",
        ...getColumnSearchProps("name"),
      },
      {
        title: "MobileNumber",
        dataIndex: "mobileNumber",
        align: "left",
        ...getColumnSearchProps("mobileNumber"),
      },
      {
        title: "Email",
        dataIndex: "email",
        align: "left",
        ...getColumnSearchProps("email"),
      },
      {
        title: "City",
        dataIndex: "city",
        align: "left",
        ...getColumnSearchProps("city"),
      },
      // {
      //   title: "Action",
      //   key: "operation",
      //   align: "center",
      //   render: (record) => (
      //     <Row gutter={[16, 16]}>
      //       <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      //         <Tooltip title="Remove User">
      //           <Popconfirm
      //             placement="left"
      //             title="Are you sure, you want to delete this User?"
      //             onConfirm={(e) => deleteUser(e, record)}
      //             icon={
      //               <QuestionCircleOutlined
      //                 style={{
      //                   color: "#d91d0f",
      //                 }}
      //               />
      //             }
      //           >
      //             <DeleteOutlined
      //               style={{
      //                 color: "#d91d0f",
      //                 fontSize: "15px",
      //               }}
      //               size="small"
      //               shape="circle"
      //             />
      //           </Popconfirm>
      //         </Tooltip>
      //       </Col>
      //     </Row>
      //   ),
      // },
    ];
    let userData = [];
    userData = e.members;

    return (
      <Table
        scroll={{ x: 300 }}
        columns={userCol}
        dataSource={userData}
        rowKey="_id"
      />
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <FloatLabel
          label={`Search ${dataIndex}`}
          // name="selectedKeys[0]"
          value={selectedKeys[0]}
        >
          <Input
            ref={searchInput}
            // placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
        </FloatLabel>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const campaignCol = [
    {
      title: "Title",
      dataIndex: "title",
      align: "left",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Status",
      key: "operation",
      align: "left",

      render: (record) => {
        return (
          <>
            {record.status === "Completed" ? (
              <Tag color="#2db7f5">{record.status}</Tag>
            ) : (
              <Tag color="rgb(252 101 25)">{record.status}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      align: "left",
      render: (record) => {
        return dayjs(record).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "Scheduled At",
      dataIndex: "scheduledDate",
      align: "left",
      render: (record) => {
        return dayjs(record).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "Total Members",
      key: "operation",
      align: "center",
      render: (record) => {
        return (
          <>
            {record.members.length > 0 ? (
              <Tag color="geekblue">{record.members.length}</Tag>
            ) : (
              <Tag color="volcano">{record.members.length}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "_id",
      // align: "center",
      render: (record) => {
        return (
          <>
            {record.status === "Completed" ? (
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <ViewCampaignDetails campaign={record} />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ViewCampaignDetails campaign={record} />
                </Col>
                {/** <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                <UpdateCampaign campaign={record} />
              </Col> */}
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip title="Delete Campaign">
                    <Popconfirm
                      placement="left"
                      title="Are you sure, you want to delete this Campaign?"
                      onConfirm={() => onDeleteGroup(record._id)}
                      // onCancel={() => this.cancelCallOnDelete()}
                      icon={
                        <QuestionCircleOutlined
                          style={{
                            color: "#d91d0f",
                          }}
                        />
                      }
                    >
                      <DeleteOutlined
                        twoToneColor="#000000"
                        style={{
                          fontSize: "15px",
                        }}
                        size="small"
                        shape="circle"
                      />
                    </Popconfirm>
                  </Tooltip>
                </Col>
              </Row>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="card">
      <div className="card-body">
        <Table
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownCircleTwoTone onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightCircleTwoTone onClick={(e) => onExpand(record, e)} />
              ),
          }}
          columns={campaignCol}
          dataSource={campaigns}
          rowKey="_id"
          align="left"
          loading={loading}
          // scroll={{
          //   y: 400,
          // }}
        />
      </div>
    </div>
  );
}

export default ListCampaign;
