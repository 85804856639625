import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  Input,
  Tooltip,
  Checkbox,
  Select,
  Upload,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FloatLabel from "../../../util/FloatLabel";
import { createBulkUsers, getAllUsers } from "../../../redux/user/user-actions";
import { createGroup } from "../../../redux/group/group-actions";
import { InboxOutlined } from "@ant-design/icons";

const { Option } = Select;

function CreateGroup(props) {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [userList, setUserList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [userListFromCSV, setUserListFromCSV] = useState(false);
  const [disableUpload, setDisableUpload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  let users = useSelector((state) => state.user.users);

  const { Dragger } = Upload;

  const onUsersChange = (value) => {
    let data = value.map((e) => {
      return JSON.parse(e);
    });

    setUserList(data);
  };

  const readFileContent = async (fileObj) => {
    console.log("inside read file content", fileObj);
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;

      console.log(fileContent);

      const usersObj = createUsersObj(fileContent);

      setNewUsers(usersObj);
    };

    reader.readAsText(fileObj);
  };
  const createUsersObj = (usersString) => {
    let lines = usersString.split("\n");
    console.log("string inside create obj", lines);
    let headers = lines[0].split("\r");

    headers = headers[0].split(",");
    console.log("headers inside create obj", headers);
    lines.shift();
    console.log("lines inside create obj after removing headers ", lines);
    let final_lines = [];
    lines.map((user) => {
      if (user) {
        user = user.split("\r");
        console.log("user inside map", user);
        user = user[0].split(",");
        let obj = {};
        obj["name"] = user[0].trimEnd();
        obj["email"] = user[1].trimEnd();
        obj["mobileNumber"] = user[2].trimEnd();
        obj["city"] = user[3].trimEnd();
        obj["state"] = user[4].trimEnd();
        obj["country"] = user[5].trimEnd();
        final_lines.push(obj);
      }
    });
    console.log("final liness ", final_lines);
    return final_lines;
  };
  const propsForFileUpload = {
    name: "file",
    multiple: false,
    accept: "text/csv",
    maxCount: 1,

    fileList: open ? fileList : [],
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      // callBeforeUpload(file);

      return false; // Prevent automatic file upload
    },
    onChange(info) {
      const { status } = info.file;
      console.log("status inside on change", status);
      console.log("info inside on change", info.fileList[0]);
      const fileObj = info.fileList[0];
      if (status !== "uploading" && status !== "removed") {
        console.log("origin file obj", fileObj.originFileObj);
        readFileContent(fileObj.originFileObj);
      }
      setFileList(info.fileList);
    },

    onDrop(e) {
      console.log("#Dropped files", e.dataTransfer.files);
    },
  };

  const onCheckChange = (e) => {
    if (e.target.checked) {
      setUserList(users);
      setChecked(e.target.checked);
    } else {
      setUserList([]);
      setChecked(e.target.checked);
    }
  };
  useEffect(() => {
    if (fileList.length > 0) {
      console.log("inside if", fileList.length);
      setChecked(true);
    } else setChecked(false);
  }, [fileList]);
  useEffect(() => {
    if (userList.length > 0) {
      console.log("inside if of userlist", userList.length);
      setDisableUpload(true);
    } else setDisableUpload(false);
  }, [userList]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const onSubmit = async () => {
    setSaveLoading(true);
    if (userList.length > 0) {
      let groupObj = { name, members: userList };
      dispatch(createGroup(groupObj))
        .then((res) => {
          console.log("response after creating group", res);
          setSaveLoading(false);
          message.success(`${name} group created`);
          handleModalCancel();
        })
        .catch((err) => {
          console.log("error while creating group", err);
        });
    } else {
      dispatch(createBulkUsers(newUsers))
        .then((res) => {
          console.log("response from creating  users", res);
          let arr = [];
          if (res.payload) {
            dispatch(getAllUsers())
              .then((res) => {
                const result = res.payload;
                console.log(
                  "response from get all users inside on submit",
                  result
                );
                console.log("newly added users inside on submit", newUsers);
                newUsers.map((user) => {
                  let found = result.find((e) => e.email === user.email);
                  arr.push(found);
                });
                console.log("found new users==", arr);
                let groupObj = { name, members: arr };
                dispatch(createGroup(groupObj))
                  .then((res) => {
                    console.log("response after creating group", res);
                    setSaveLoading(false);
                    message.success(`${name} group created`);
                    handleModalCancel();
                  })
                  .catch((err) => {
                    console.log("error while creating group", err);
                  });
              })
              .catch((err) => {
                console.log("error from get all users inside on submit", err);
              });
          }
        })
        .catch((err) => {
          console.log("error while creating users", err);
        });
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleModalCancel = () => {
    setOpen(false);
    setName("");
    setUserList([]);
    setFileList([]);
    setChecked(false);
  };

  return (
    <>
      <button onClick={showModal} class="float-end create-btn" role="button">
        <span class="text">
          Create
          <svg
            width={15}
            height={15}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 mb-1 ms-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
            />
          </svg>
        </span>
      </button>
      <Modal
        open={open}
        onCancel={handleModalCancel}
        title=""
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="create-group"
          className="create-group"
          onFinish={onSubmit}
          initialValues={{ remember: true }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              margin: "0 0 10px 0",
              justifyContent: "center",
            }}
          >
            <h6 style={{ textAlign: "center" }}>Create Group</h6>
          </div>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FloatLabel label="Name" name="name" value={name}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Name!",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col xs={22} sm={22} md={23} lg={23} xl={23}>
              <FloatLabel label="Select Users" name="userList" value={userList}>
                <Form.Item
                  name="userList"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Users!",
                  //   },
                  // ]}
                >
                  <Select
                    allowClear
                    maxTagCount="responsive"
                    maxTagPlaceholder={(omittedValues) =>
                      `+ ${omittedValues.length} Users ...`
                    }
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    // defaultValue={() =>
                    //   userList.map((item) => item.name + " - " + item.id)
                    // }
                    value={userList}
                    disabled={checked}
                    onChange={onUsersChange}
                  >
                    {users.map((data, i) => (
                      <Option key={i} value={JSON.stringify(data)}>
                        {data.name} ({data.mobileNumber})
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={2} sm={2} md={1} lg={1} xl={1}>
              <Tooltip
                title={
                  !checked && fileList.length > 0
                    ? "Select all Users"
                    : "Select users manually"
                }
              >
                <p
                  style={{
                    margin: "6px 0 0 0",
                  }}
                >
                  <Checkbox
                    checked={checked && fileList.length === 0 ? true : false}
                    onChange={onCheckChange}
                  />
                </p>
              </Tooltip>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24}>
              <Form.Item name="upload">
                <Dragger {...propsForFileUpload} disabled={disableUpload}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag .csv file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Add bulk data of new users using .csv file and create a
                    group.
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ float: "right" }}>
            <Button
              className="close-modal"
              style={{ margin: "0 10px 0 0" }}
              onClick={handleModalCancel}
            >
              Close
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              className="ok-modal"
              loading={saveLoading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default CreateGroup;
