import { createSlice } from "@reduxjs/toolkit";
import { getAllCampaigns } from "./campaign-actions";

const initialCampaignState = {
  campaigns: [],
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialCampaignState,
  extraReducers: (builder) => {
    builder.addCase(getAllCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload;
    });
    builder.addCase(getAllCampaigns.rejected, (action, state) => {
      state.campaigns = [];
    });
    builder.addCase(getAllCampaigns.pending, (state, action) => {
      state.campaigns = [];
    });
  },
  reducers: {
    // getAllCampaigns(state, action) {
    //   state.campaigns = action.payload;
    // },
    createCampaign(state, action) {
      state.campaigns = [...state.campaigns, action.payload];
    },
    deleteCampaign(state, action) {
      let index = state.campaigns.findIndex(
        ({ _id }) => _id === action.payload._id
      );
      state.campaigns.splice(index, 1);
    },
  },
});

export const campaignActions = campaignSlice.actions;

export default campaignSlice.reducer;
