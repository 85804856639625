import { Col, Row, Button, Modal, Form, Input, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FloatLabel from "../../../util/FloatLabel";
import { EditOutlined } from "@ant-design/icons";
import { updateGroup } from "../../../redux/group/group-actions";

function UpdateGroup(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    setName(props.group.name);
    setUserList(props.group.members);
  }, [open]);

  const showModal = () => {
    setOpen(true);
  };

  const handleModalCancel = () => {
    setOpen(false);
    setUserList([]);
  };

  const onSubmit = async () => {
    let groupObj = {
      name,
      userList,
    };
    dispatch(updateGroup(groupObj, props.group._id));
    handleModalCancel();
  };
  return (
    <>
      <Tooltip title="Edit Group">
        <EditOutlined
          twoToneColor="#000000"
          style={{ fontSize: "15px", cursor: "pointer" }}
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        open={open}
        onCancel={handleModalCancel}
        title=""
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="update-group"
          className="update-group"
          onFinish={onSubmit}
          initialValues={{ remember: true }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              margin: "0 0 10px 0",
              justifyContent: "center",
            }}
          >
            <h6 style={{ textAlign: "center" }}>Update Group</h6>
          </div>
          <Row gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FloatLabel label="Name" name="name" value={name}>
                <Form.Item
                  initialValue={name}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Name!",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>
          <Form.Item style={{ float: "right" }}>
            <Button
              className="close-modal"
              style={{ margin: "0 10px 0 0" }}
              onClick={handleModalCancel}
            >
              Close
            </Button>

            <Button type="primary" htmlType="submit" className="ok-modal">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateGroup;
