import axios from "axios";
import { userActions } from "./user";
import { backend_base_url } from "../../util/constants";
import { message } from "antd";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createUser = (user) => {
  return async (dispatch) => {
    await axios
      .post(`${backend_base_url}/user`, user)
      .then((resp) => {
        dispatch(userActions.createUser(resp.data.body));
        if (resp.data.body) {
          message.success(`User ${resp.data.body.name} created successfully`);
        }
      })
      .catch((error) => console.log(error));
  };
};

// export const getAllUsers = () => {
//   return async (dispatch) => {
//     await axios
//       .get(`${backend_base_url}/user`)
//       .then((resp) => {
//         console.log("##resp.data", resp.data);
//         dispatch(userActions.getAllUsers(resp.data));
//       })
//       .catch((error) => console.log(error));
//   };
// };
export const getAllUsers = createAsyncThunk("getallusers", async () => {
  try {
    const res = await axios.get(`${backend_base_url}/user`);
    return res.data;
  } catch (error) {
    console.log("error from get all users", error);
  }
});

export const updateUser = (user, id) => {
  return async (dispatch) => {
    await axios
      .patch(`${backend_base_url}/user?id=${id}`, user)
      .then((resp) => {
        dispatch(userActions.updateUser(resp.data));
        if (resp.data) {
          message.success(`User updated successfully`);
        }
      })
      .catch((error) => console.log(error));
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    await axios
      .delete(`${backend_base_url}/user?id=${id}`)
      .then((resp) => {
        dispatch(userActions.deleteUser(resp.data));
        if (resp.data) {
          message.success(`User ${resp.data.name} deleted successfully`);
        }
      })
      .catch((error) => console.log(error));
  };
};

export const getUsersByGroupId = (groupList) => {
  return async (dispatch) => {
    await axios
      .post(`${backend_base_url}/allusers`, groupList)
      .then((resp) => {
        dispatch(userActions.getUsersByGroupId(resp.data));
      })
      .catch((error) => console.log(error));
  };
};
export const getAllCities = () => {
  return async (dispatch) => {
    await axios
      .get(`${backend_base_url}/allusers/allcity`)
      .then((resp) => {
        dispatch(userActions.getAllCities(resp.data.body));
      })
      .catch((error) => console.log(error));
  };
};

export const getUsersByCity = (city) => {
  return async (dispatch) => {
    await axios
      .post(`${backend_base_url}/allusers/allcity`, city)
      .then((resp) => {
        dispatch(userActions.getUsersByCity(resp.data.body));
      })
      .catch((error) => console.log(error));
  };
};

// export const createBulkUsers = (users) => {
//   return async (dispatch) => {
//     console.log("users inside create bulk users", users);
//     await axios
//       .post(`${backend_base_url}/user/bulkusers`, users)
//       .then((resp) => {
//         console.log("response from bulk user creation", resp);
//         dispatch(userActions.createBulkUsers(resp.data.body));
//       })
//       .catch((error) => console.log("error from bulk user creation", error));
//   };
// };

export const createBulkUsers = createAsyncThunk(
  "createBulkUsers",
  async (users) => {
    try {
      const res = await axios.post(`${backend_base_url}/user/bulkusers`, users);

      return res.data.body;
    } catch (error) {
      console.log("error from create bulk users", error);
    }
  }
);
