import React from "react";
import { Col, Row } from "antd";
import CountCards from "./pages/dashboard/CountCards";
import UpcomingCampaigns from "./pages/dashboard/UpcomingCampaigns";
import DonutChartStatus from "./pages/dashboard/DonutChartStatus";

export default function Dashboard(props) {
  return (
    <>
      <div className="container">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <h2 className="page-title">Dashboard</h2>
          </Col>
        </Row>
        <CountCards />
        <Row gutter={[32, 8]} className="mt-3">
          <UpcomingCampaigns />
          <DonutChartStatus />
        </Row>
      </div>
    </>
  );
}
