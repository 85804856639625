import React, { useState, useEffect } from "react";
import { Pie, measureTextWidth } from "@ant-design/plots";
import { Empty, Col, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getChartData } from "../../../redux/dashboard/dashboard-actions";

function DonutChartStatus(props) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const chartData = useSelector((state) => state.dashboard.chartData);

  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }

  useEffect(() => {
    asyncFetch();
  }, []);

  useEffect(() => {
    if (chartData.length > 0) {
      setData(chartData);
    }
  }, [chartData]);

  const asyncFetch = async () => {
    dispatch(getChartData());
  };

  const config = {
    width: 275,
    height: 275,
    color: ["rgb(17 82 147)", "rgb(96, 130, 182)"],
    // color: ["rgba(94, 200, 90, 0.9)", "rgba(255, 112, 67, 0.9)"],
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "status",
    radius: 1,
    innerRadius: 0.64,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    label: {
      type: "spider",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "{value}",
    },
    statistic: {
      title: {
        offsetY: -4,
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
          width: "300px",
          fontSize: 16,
          fontWeight: "500",
          opacity: "0.9",
        },
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));

          const text = datum ? datum.status : "Campaings";
          return renderStatistic(d, text, {
            fontSize: 18,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
          fontSize: 14,
          fontWeight: "200",
          opacity: "0.7",
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? datum.value
            : data.reduce((r, d) => {
                return {
                  value: r.value + d.value,
                };
              }).value;
          return renderStatistic(width, text, {
            fontSize: 18,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };
  return (
    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <h6 className="text-secondary  mb-3">Status</h6>
          </div>

          {(() => {
            if (!data.length) {
              return (
                <div className="spinLoader">
                  <Spin />
                </div>
              );
            } else {
              return <Pie {...config} />;
            }
          })()}
        </div>
      </div>
    </Col>
  );
}

export default DonutChartStatus;
