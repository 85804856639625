import { Col, Row, Button, Modal, Form, Input, Tooltip, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FloatLabel from "../../../util/FloatLabel";
import { EditOutlined } from "@ant-design/icons";
import { updateUser } from "../../../redux/user/user-actions";
import { Country, City, State } from "country-state-city";

const { Option } = Select;

function UpdateUser(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({ code: "", name: "" });
  const [states, setStates] = useState([]);
  const [state, setState] = useState({ code: "", name: "" });

  useEffect(() => {
    setName(props.user.name);
    setCity(props.user.city);
    setState({ code: "", name: props.user.state });
    setCountry({ code: "", name: props.user.country });
    setEmail(props.user.email);
    setMobileNumber(props.user.mobileNumber);
  }, [open]);

  const handleChange = (e, value) => {
    setCountry({ code: value.value, name: value.key });
  };

  const handleStateChange = (e, value) => {
    setState({ code: value.value, name: value.key });
  };

  const handleCityChange = (e, value) => {
    setCity(value.value);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleModalCancel = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    let userObj = {
      name,
      mobileNumber,
      email,
      city,
      state: state.name,
      country: country.name,
    };
    dispatch(updateUser(userObj, props.user._id));
    handleModalCancel();
  };
  return (
    <>
      <Tooltip title="Edit User">
        <EditOutlined
          twoToneColor="#000000"
          style={{ fontSize: "15px", cursor: "pointer" }}
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        open={open}
        onCancel={handleModalCancel}
        title=""
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="update-user"
          className="update-user"
          onFinish={onSubmit}
          initialValues={{ remember: true }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              margin: "0 0 10px 0",
              justifyContent: "center",
            }}
          >
            <h6 style={{ textAlign: "center" }}>Update User</h6>
          </div>
          <Row gutter={[8, 0]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Name" name="name" value={name}>
                <Form.Item
                  initialValue={name}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Name!",
                    },
                  ]}
                >
                  <Input
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Email" name="email" value={email}>
                <Form.Item
                  initialValue={email}
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please enter E-mail!",
                    },
                  ]}
                >
                  <Input
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel
                label="Mobile No."
                name="mobileNumber"
                value={mobileNumber}
              >
                <Form.Item
                  initialValue={mobileNumber}
                  name="mobileNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile number!",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        var hasAlphabets = /^[0-9]+$/;
                        if (
                          parseInt(getFieldValue("mobileNumber")) &&
                          hasAlphabets.test(getFieldValue("mobileNumber"))
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Should contain numbers!");
                      },
                    }),
                  ]}
                >
                  <Input
                    type="string"
                    name="mobileNumber"
                    id="mobileNumber"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel
                label="Select Country"
                name="countries"
                value={countries}
              >
                <Form.Item
                  // initialValue={country}
                  name="countries"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Country",
                  //   },
                  // ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    placeholder="Select Country"
                    onChange={handleChange}
                    onClick={() => setCountries(Country.getAllCountries())}
                    onFocus={() => setCountries(Country.getAllCountries())}
                    value={countries}
                  >
                    {countries?.map((data) => (
                      <Option key={data.name} value={data.isoCode}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Select State" name="states" value={states}>
                <Form.Item
                  // initialValue={state}
                  name="states"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select State",
                  //   },
                  // ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    placeholder="Select State"
                    onChange={handleStateChange}
                    onClick={() =>
                      setStates(State.getStatesOfCountry(country.code))
                    }
                    onFocus={() =>
                      setStates(State.getStatesOfCountry(country.code))
                    }
                    value={states}
                  >
                    {states?.map((data) => (
                      <Option key={data.name} value={data.isoCode}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Select City" name="cities" value={cities}>
                <Form.Item
                  // initialValue={city}
                  name="cities"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select City",
                  //   },
                  // ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    placeholder="Select City"
                    onChange={handleCityChange}
                    onClick={() =>
                      setCities(City.getCitiesOfState(country.code, state.code))
                    }
                    onFocus={() =>
                      setCities(City.getCitiesOfState(country.code, state.code))
                    }
                    value={cities}
                  >
                    {cities.map((data, i) => (
                      <Option key={i} value={data.name}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>
          <Form.Item style={{ float: "right" }}>
            <Button
              className="close-modal"
              style={{ margin: "0 10px 0 0" }}
              onClick={handleModalCancel}
            >
              Close
            </Button>

            <Button type="primary" htmlType="submit" className="ok-modal">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateUser;
