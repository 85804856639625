import { createSlice } from "@reduxjs/toolkit";
import { createBulkUsers, getAllUsers } from "./user-actions";

const initialUserState = {
  users: [],
  cities: [],
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  extraReducers: (builder) => {
    builder.addCase(createBulkUsers.fulfilled, (state, action) => {
      state.users = [...state.users, ...action.payload];
    });
    builder.addCase(createBulkUsers.pending, (state, action) => {
      state.users = [...state.users];
    });
    builder.addCase(createBulkUsers.rejected, (state, action) => {
      state.users = [...state.users];
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.users = [];
    });
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.users = [];
    });
  },
  reducers: {
    // getAllUsers(state, action) {
    //   state.users = action.payload;
    // },
    getUsersByGroupId(state, action) {
      state.users = action.payload;
    },
    getUsersByCity(state, action) {
      state.users = action.payload;
    },
    getAllCities(state, action) {
      state.cities = action.payload;
    },
    createUser(state, action) {
      state.users = [...state.users, action.payload];
    },
    deleteUser(state, action) {
      let index = state.users.findIndex(
        ({ _id }) => _id === action.payload._id
      );
      state.users.splice(index, 1);
    },
    updateUser(state, action) {
      const index = state.users.findIndex(
        ({ _id }) => _id === action.payload._id
      );
      if (index !== -1) {
        state.users[index] = action.payload;
      }
    },
    // createBulkUsers(state, action) {
    //   state.users = [...state.users, ...action.payload];
    // },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
