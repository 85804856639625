import { configureStore } from "@reduxjs/toolkit";
import campaignReducer from "./campaign/campaign";
import userReducer from "./user/user";
import groupReducer from "./group/group";
import dashboardReducer from "./dashboard/dashboard";

const store = configureStore({
  reducer: {
    campaign: campaignReducer,
    group: groupReducer,
    user: userReducer,
    dashboard: dashboardReducer,
  },
});

export default store;
