import React from "react";
import { Layout, Dropdown, Avatar } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import SiderDrawerPhone from "../dashboard/SiderDrawerPhone";
import { useMsal } from "@azure/msal-react";
import iconSvg from "../../assests/img/avatar1.gif";
const { Header } = Layout;

export default function HeaderAdmin(props) {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.logoutRedirect();
  };

  const items = [
    {
      label: (
        <span onClick={handleLoginRedirect}>
          <LogoutOutlined style={{ fontSize: "14px" }} />
          <span
            className="ms-1"
            style={{ paddingLeft: "5px", fontSize: "14px" }}
          >
            Sign Out
          </span>
        </span>
      ),
      key: "1",
    },
  ];
  return (
    <Header
      className="header-admin site-layout-background"
      style={{ padding: 0 }}
    >
      {React.createElement(
        props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        {
          className: "trigger",
          onClick: props.toggle,
        }
      )}
      <SiderDrawerPhone user={props.user} />

      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <div
          className="avatar-user-details"
          onClick={(e) => e.preventDefault()}
        >
          <div className="avatar-box">
            <span className="avatar-name">
              <strong>{props.user ? props.user.name : ""}</strong>
            </span>
            <div className="avatar-img">
              <Avatar
                style={{ backgroundColor: "#3b404f" }}
                size={40}
                // icon={<UserOutlined />}
              >
                <img
                  style={{ margin: "-10px 0 0 -10px" }}
                  width={40}
                  height={35}
                  src={iconSvg}
                />
              </Avatar>
            </div>
          </div>
        </div>
      </Dropdown>
    </Header>
  );
}
