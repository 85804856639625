import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  Input,
  Tooltip,
  DatePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FloatLabel from "../../../util/FloatLabel";
import { EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const { TextArea } = Input;

function UpdateCampaign(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [campaignMessage, setCampaignMessage] = useState("");
  const [scheduledDate, setScheduledDate] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    setCampaignMessage(props.campaign.campaignMessage);
    setScheduledDate(props.campaign.scheduledDate);
    setTitle(props.campaign.title);
  }, [open]);

  const onChange = (event) => {
    setCampaignMessage(event.target.value);
  };

  const onDateChange = (value, dateString) => {
    let utcDate = new Date(dateString).toUTCString();
    let convertedUtcDate = JSON.stringify(new Date(utcDate)).replaceAll(
      '"',
      ""
    );
    setScheduledDate(convertedUtcDate);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today

    return current && current < dayjs().startOf("day");
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleModalCancel = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    let campaignObj = {
      title,
      scheduledDate,
      campaignMessage,
    };
    // dispatch(updateCampaign(campaignObj, props.campaign._id));
    handleModalCancel();
  };
  return (
    <>
      <Tooltip title="Edit Campaign">
        <EditOutlined
          twoToneColor="#000000"
          style={{ fontSize: "15px", cursor: "pointer" }}
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        open={open}
        onCancel={handleModalCancel}
        title=""
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="update-campaign"
          className="update-campaign"
          onFinish={onSubmit}
          initialValues={{ remember: true }}
        >
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              margin: "0 0 10px 0",
              justifyContent: "center",
            }}
          >
            <h6 style={{ textAlign: "center" }}>Update Campaign</h6>
          </div>
          <Row gutter={[8, 0]}>
            {/**<Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name="upload" valuePropName="fileList">
                {file ? (
                  <Row gutter={[8, 8]}>
                    {(fileType == "image/png" || fileType == "image/jpeg") && (
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div style={{ textAlign: "center" }}>
                          <img width={125} height={125} src={file} />
                        </div>
                      </Col>
                    )}
                    {fileType == "video/mp4" && (
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div style={{ textAlign: "center" }}>
                          <video width="125" height="125" controls>
                            <source src={file} type="video/mp4" />
                          </video>
                        </div>
                      </Col>
                    )}
                    {fileType == "application/pdf" && (
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div style={{ textAlign: "center" }}>
                          <img width={125} height={125} src={pdfImg} />
                        </div>
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Row>
                        <div style={{ margin: "0 10px 0 auto" }}>
                          {fileType !== "video/mp4" && (
                            <PreviewUploadedImage
                              file={file}
                              fileType={fileType}
                            />
                          )}
                        </div>
                        <div style={{ margin: "0 auto 0 0" }}>
                          <button
                            onClick={removeUploadedFile}
                            style={{ width: "50px" }}
                            type="button"
                            class="btn btn-outline-danger btn-sm"
                          >
                            <DeleteOutlined />
                          </button>
                        </div>
                      </Row>
                    </Col>
                    <div style={{ margin: "0 auto" }}>
                      <label>
                        <h6>{fileName}</h6>
                      </label>
                    </div>
                  </Row>
                ) : (
                  <div className="text-center">
                    <label
                      for="inputFile"
                      style={{
                        fontSize: "25px",
                        textAlign: "-webkit-center",
                      }}
                    >
                      <div class="drag-area ">
                        <h6>Upload</h6> <PlusOutlined />
                        <input
                          class="form-control"
                          type="file"
                          id="inputFile"
                          name="inputFile"
                          value={inputFile}
                          onChange={handleFileChange}
                          hidden
                          accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4"
                        />
                      </div>
                    </label>
                  </div>
                )}
              </Form.Item>
            </Col> */}

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel label="Campaign Title" name="title" value={title}>
                <Form.Item
                  initialValue={title}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please type Title!",
                    },
                  ]}
                >
                  <Input
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <FloatLabel
                label="Select Date"
                name="scheduledDate"
                value={scheduledDate}
              >
                <Form.Item
                  initialValue={dayjs(scheduledDate)}
                  name="scheduledDate"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Date!",
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    disabledDate={disabledDate}
                    placeholder={false}
                    showTime
                    onChange={onDateChange}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FloatLabel
                label="Type about Campaign..."
                name="campaignMessage"
                value={campaignMessage}
              >
                <Form.Item
                  initialValue={campaignMessage}
                  name="campaignMessage"
                  rules={[
                    {
                      required: true,
                      message: "Please type description!",
                    },
                  ]}
                >
                  <TextArea
                    name="campaignMessage"
                    value={campaignMessage}
                    onChange={onChange}
                    rows={6}
                  />
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>
          <Form.Item style={{ float: "right" }}>
            <Button
              className="close-modal"
              style={{ margin: "0 10px 0 0" }}
              onClick={handleModalCancel}
            >
              Close
            </Button>

            <Button type="primary" htmlType="submit" className="ok-modal">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UpdateCampaign;
